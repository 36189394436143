import { Container, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import PostCard from "../components/PostCard";
import { PostContext } from "../context/post-context";

const UserPage = () => {
	const postCtx = useContext(PostContext);
	const { userId } = useParams();

	useEffect(() => {
		postCtx.updaters.loadPosts!();
	}, [postCtx.updaters.loadPosts]);

	const loadedPosts = postCtx.posts.filter(post => post.author === userId);

	return (
		<Container maxWidth="md">
			<Typography variant="h2" sx={{ textAlign: "center" }}>
				{userId}'s Posts:
			</Typography>
			{loadedPosts.length === 0 ? (
				<Typography
					variant="h4"
					sx={{ textAlign: "center", mt: "10vh", fontWeight: "bold" }}
				>
					This User has no posts.
				</Typography>
			) : (
				loadedPosts.map(post => <PostCard key={post.id} data={post} />)
			)}
		</Container>
	);
};

export default UserPage;
