import { useContext, useEffect } from "react";

import { PostContext } from "../context/post-context";
import PostCard from "../components/PostCard";

import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import Post from "../models/post-model";

const Feed: React.FC<{posts: Post[]}> = props => {

    const ctx = useContext(PostContext);

	useEffect(() => {
		ctx.updaters.loadPosts!();
	}, [ctx.updaters.loadPosts]);

	return (
		<Container maxWidth="md">
			{props.posts.length === 0 ? (
				<Typography
					variant="h4"
					sx={{ textAlign: "center", mt: "10vh", fontWeight: "bold" }}
				>
					Loading...
				</Typography>
			) : (
				props.posts.map(post => <PostCard key={post.id} data={post} />)
			)}
		</Container>
	);
};

export default Feed;
