import { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { AuthContext } from "./context/auth-context";
import { PostContext } from "./context/post-context";

import LOGO from "./media/H_logo.png";

import { AppBar, Toolbar, CssBaseline, Box } from "@mui/material";

import Feed from "./pages/Feed";
import NewPost from "./pages/NewPost";
import NavigationLink from "./components/NavigationLink";
import SignIn from "./pages/SignIn";
import LogOut from "./pages/LogOut";
import UserPage from "./pages/UserPage";

function App() {
	const authCtx = useContext(AuthContext);
	const postsCtx = useContext(PostContext);
    const navigate = useNavigate();

	useEffect(() => {
		authCtx.authFunctions.checkLocalStorage();
	}, []);

	const pages = authCtx.user
		? [
				["Home", "/"],
				["New Post", "/new-post"],
				["Profile", `/user/${authCtx.username}`],
				["Log Out", "/log-out"]
		  ]
		: [
				["Home", "/"],
				["New Post", "/new-post"],
				["Sign In", "/sign-in"]
		  ];

	const goHome = () => {
        navigate("/");
    };

	return (
		<Box className="App" sx={{ bgcolor: "#D5BF86", minHeight: "100vh" }}>
			<CssBaseline />
			<AppBar position="sticky">
				<Toolbar>
					<Box
                        className="logo"
						component="img"
						src={LOGO}
						onClick={goHome}
						sx={{
							display: { xs: "none", md: "flex" },
							maxHeight: "64px"
						}}
					/>
					<Box
						sx={{
							flexGrow: 1,
							display: "flex"
						}}
					>
						{pages.map(page => (
							<NavigationLink
								href={page[1]}
								key={page[0]}
								sx={{ my: 2, color: "white", display: "block", fontSize: {xs: "0.76rem", sm: ".875rem"} }}
							>
								{page[0]}
							</NavigationLink>
						))}
					</Box>
				</Toolbar>
			</AppBar>
			<Box component="main" sx={{ pb: "10px" }}>
				<Routes>
					<Route path="/" element={<Feed posts={postsCtx.posts} />} />
					<Route path="/new-post" element={<NewPost />} />
					<Route path="/sign-in" element={<SignIn />} />
					<Route path="/log-out" element={<LogOut />} />
					<Route path="/user/:userId" element={<UserPage />} />
				</Routes>
			</Box>
		</Box>
	);
}

export default App;
