import { createContext, ReactNode, useState } from "react";

import {
	get,
	ref,
	query,
	orderByChild,
	set,
	remove,
	increment,
	push
} from "firebase/database";
import { db } from "../firebase";
import Post from "../models/post-model";
import Comment from "../models/comment-model";

export const PostContext = createContext({
	posts: [],
	votes: {},
	updaters: {}
} as {
	posts: Post[];
	votes: any;
	updaters: {
		loadPosts?: () => Promise<void>;
		voteOnPost?: (
			postID: string,
			vote: string,
			username: string
		) => Promise<void>;
		newComment?: (
			parentID: string,
			text: string,
			author: string
		) => Promise<void>;
	};
});

export const PostContextProvider = ({ children }: { children: ReactNode }) => {
	const [posts, setPosts] = useState([] as Post[]);
	const [votes, setVotes] = useState({});

	const updatingFunctions = {
		loadPosts: async () => {
			let loadedPosts: Post[] = [];
			try {
				const temp = (
					await get(
						query(ref(db, "posts/"), orderByChild("timestamp"))
					)
				).val();
				for (const key in temp) {
					const value = temp[key];
					let comments: Comment[] = [];

					for (const commentID in value.comments) {
						const comm = value.comments[commentID];
						comments.push(
							new Comment(comm.author, comm.text, commentID)
						);
					}
					let score = 0;

                    for (const voter in value.votes) {
                        score += value.votes[voter];
                    }
					loadedPosts.push(
						new Post(
							value.author,
							value.content,
							key,
							score,
							comments,
							value.timestamp
						)
					);
					setVotes(votes => ({ ...votes, [key]: value.votes }));
				}

				setPosts(loadedPosts.reverse());
			} catch (error: any) {
				alert(error.message);
			}
		},
		voteOnPost: async (postID: string, vote: string, username: string) => {
			try {
				if (vote === "LIKE") {
					await set(ref(db, `posts/${postID}/votes/${username}`), 1);
				} else if (vote === "UNLIKE") {
					await remove(ref(db, `posts/${postID}/votes/${username}`));
				} else if (vote === "DISLIKE") {
					await set(ref(db, `posts/${postID}/votes/${username}`), -1);
				} else if (vote === "UNDISLIKE") {
					await remove(ref(db, `posts/${postID}/votes/${username}`));
				}
			} catch (error) {
				console.log(error);
			}
		},
		newComment: async (parentID: string, text: string, author: string) => {
			let commentID;
			try {
				commentID = await push(ref(db, `posts/${parentID}/comments`), {
					author,
					text
				});
				await set(
					ref(db, `user/${author}/comments/${commentID}`),
					parentID
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<PostContext.Provider
			value={{ posts, votes, updaters: updatingFunctions }}
		>
			{children}
		</PostContext.Provider>
	);
};
