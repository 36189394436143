import { CardHeader, Link } from "@mui/material";

const PostHeader: React.FC<{ authorId: string }> = props => {
	return (
		<Link href={`/user/${props.authorId}`} color="inherit" underline="hover" >
			<CardHeader title={props.authorId} />
		</Link>
	);
};

export default PostHeader;
