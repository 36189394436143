import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/system";

import { AuthContext } from "../context/auth-context";
import { Alert } from "@mui/material";

const LogOut = () => {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		authCtx.authFunctions.logOut();
		setTimeout(() => {
			navigate("/");
		}, 1500);
	}, [authCtx.authFunctions, navigate]);

	return (
		<Container maxWidth="md">
			<Alert severity="success" sx={{mt: 5, fontWeight: "bold", fontSize: 20}} >You have been logged out. Redirecting...</Alert>
		</Container>
	);
};

export default LogOut;
