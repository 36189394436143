import { useState, useContext } from "react";

import { push, ref } from "firebase/database";
import { db } from "../firebase";
import { AuthContext } from "../context/auth-context";

import { Box, Button, TextField } from "@mui/material";
import { Container } from "@mui/system";
import Post from "../models/post-model";

const NewPost: React.FC = () => {
	const authCtx = useContext(AuthContext);

	const [textInputValue, setTextInputValue] = useState("");

	const textInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTextInputValue(event.target.value);
	};

	const submitButtonHandler = async (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();

		const payload = new Post(authCtx.username, textInputValue);

		if (authCtx.username === "") {
			payload.author = `Guest ${Math.floor(
				Math.random() * 900000000 + 100000000
			)}`;
		}

		delete payload.id;
		let temp: any;
		try {
			temp = await push(ref(db, "posts/"), payload);
			await push(ref(db, `user/${authCtx.username}/posts`), temp.key);
		} catch (error: any) {
			alert(error.message);
		}

		setTextInputValue("");
	};

	return (
		<Container maxWidth="md">
			<Box
				component="form"
				sx={{
					bgcolor: "#F1F0CC",
					p: 5,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					gap: 5
				}}
			>
				<TextField
					required
					id="text-input"
					label="Post Content"
					value={textInputValue}
					multiline
					rows={4}
					variant="filled"
					onChange={textInputHandler}
					sx={{ width: "100%" }}
				/>
				<Button
					variant="outlined"
					type="submit"
					size="large"
					onClick={submitButtonHandler}
				>
					Submit
				</Button>
			</Box>
		</Container>
	);
};

export default NewPost;
