class Comment {
	id: string;
	author: string;
	content: string;

	constructor(author: string, text: string, id: string) {
		this.author = author;
		this.content = text;
		this.id = id;
	}
}

export default Comment;
