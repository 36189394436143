import { useState, useContext } from "react";

import { PostContext } from "../../context/post-context";
import { AuthContext } from "../../context/auth-context";

import { Box, Button, Collapse, TextField } from "@mui/material";
import Comment from "../../models/comment-model";
import CommentCard from "../CommentCard";

const PostComments: React.FC<{
	in: boolean;
	comments: Comment[];
	postID: string;
}> = props => {
	const PostCtx = useContext(PostContext);
	const AuthCtx = useContext(AuthContext);

	const [commentText, setCommentText] = useState("");

	const newCommentChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		event.preventDefault();

		setCommentText(event.target.value);
	};

	const submitCommentHandler = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();

		if (commentText.length > 0 && AuthCtx.username) {
			PostCtx.updaters.newComment!(
				props.postID,
				commentText,
				AuthCtx.username
			);

			setCommentText("");
		}
	};

	return (
		<Collapse in={props.in}>
			<Box component="form" sx={{ m: 2, display: 'flex'   }}>
				<TextField
					value={commentText}
					onChange={newCommentChangeHandler}
					label="Add a Comment"
					sx={{ borderColor: "black", width: "80%" }}
				/>
				<Button
					onClick={submitCommentHandler}
					type="submit"
                    variant="contained"
					sx={{ ml: 1, flexGrow: 1 }}
				>
					Submit
				</Button>
			</Box>
			<Box>
				{props.comments.map(comment => (
					<CommentCard key={comment.id} data={comment} />
				))}
			</Box>
		</Collapse>
	);
};

export default PostComments;
