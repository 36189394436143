import { useState, useContext } from "react";

import { AuthContext } from "../context/auth-context";

import { Button, TextField, Typography, Link, Alert } from "@mui/material";
import { Box, Container } from "@mui/system";

const SignIn = () => {
	const authCtx = useContext(AuthContext);

	const [usernameInputValue, setUsernameInputValue] = useState("");
	const [emailInputValue, setEmailInputValue] = useState("");
	const [passwordInputValue, setPasswordInputValue] = useState("");
	const [isSigningIn, setIsSigningIn] = useState(true);
	const [errorMessage, setErrorMessage] = useState({
		status: "untouched",
		message: ""
	});

	const errorStatuses = {
		email:
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "invalid-email" ||
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "user-disabled" ||
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "email-already-in-use" ||
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "user-not-found",
		username:
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "username-taken",
		password:
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "wrong-password" ||
			errorMessage.message.slice(
				errorMessage.message.indexOf("auth/") + 5,
				-2
			) === "weak-password"
	};

	const toHuman = (inputString: string) => {
		return (
			inputString.charAt(inputString.indexOf("auth/") + 5).toUpperCase() +
			inputString
				.replaceAll("-", " ")
				.slice(inputString.indexOf("auth/") + 6, -2) +
			"."
		);
	};

	const switchProcessHandler = () => {
		setIsSigningIn(s => !s);
		setErrorMessage({
			status: "untouched",
			message: ""
		});
	};

	const usernameChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setUsernameInputValue(event.target.value);
	};

	const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailInputValue(event.target.value);
	};

	const passwordChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setPasswordInputValue(event.target.value);
	};

	const logInHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		const temp = await authCtx.authFunctions.logIn(
			emailInputValue,
			passwordInputValue
		);
		setErrorMessage({
			status: temp.status,
			message: temp.message
		});

		setEmailInputValue("");
		setPasswordInputValue("");
	};

	const signUpHandler = async (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();

		const temp = await authCtx.authFunctions.createAccount(
			emailInputValue,
			usernameInputValue,
			passwordInputValue
		);

		setErrorMessage({ status: temp.status, message: temp.message });

		setEmailInputValue("");
		setUsernameInputValue("");
		setPasswordInputValue("");
	};

	return (
		<Container maxWidth="sm">
			<Box
				component="form"
				sx={{
					bgcolor: "#F1F0CC",
					p: 5,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					gap: "20px"
				}}
			>
				<Typography component="h2" variant="h4">
					{isSigningIn ? "Log In" : "Sign Up"}
				</Typography>

				<TextField
					error={errorStatuses.email}
					required
					autoFocus
					id="email-input"
					label="Email"
					type="email"
					value={emailInputValue}
					onChange={emailChangeHandler}
				/>

				{errorStatuses.email && (
					<Alert variant="outlined" severity="error">
						{toHuman(errorMessage.message)}
					</Alert>
				)}

				{!isSigningIn && (
					<TextField
						error={errorStatuses.username}
						required
						id="username-input"
						label="Username"
						value={usernameInputValue}
						onChange={usernameChangeHandler}
					/>
				)}

				{errorStatuses.username && !isSigningIn && (
					<Alert variant="outlined" severity="error">
						{toHuman(errorMessage.message)}
					</Alert>
				)}

				<TextField
					error={errorStatuses.password}
					required
					type="password"
					id="password-input"
					label="Password"
					value={passwordInputValue}
					onChange={passwordChangeHandler}
				/>

				{errorStatuses.password && (
					<Alert variant="outlined" severity="error">
						{toHuman(errorMessage.message)}
					</Alert>
				)}

				<Button
					type="submit"
					variant="contained"
					onClick={isSigningIn ? logInHandler : signUpHandler}
				>
					{isSigningIn ? "Log In" : "Sign Up"}
				</Button>
				{isSigningIn ? (
					<Typography>
						Don't have an account?{" "}
						<Link underline="none" onClick={switchProcessHandler}>Sign up.</Link>
					</Typography>
				) : (
					<Typography>
						Already have an account?{" "}
						<Link underline="none" onClick={switchProcessHandler}>Log In.</Link>
					</Typography>
				)}
			</Box>
		</Container>
	);
};

export default SignIn;
