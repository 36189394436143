import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { PostContextProvider } from "./context/post-context";
import { AuthContextProvider } from "./context/auth-context";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<BrowserRouter>
		<PostContextProvider>
			<AuthContextProvider>
				<App />
			</AuthContextProvider>
		</PostContextProvider>
	</BrowserRouter>
);
