import { Button } from "@mui/material";
import { Link } from "react-router-dom";



const NavigationLink: React.FC<{
	href: string;
	key: string;
	sx: any;
	children: string;
}> = props => {
	return (
		<Link className="navigation-link" to={props.href} >
			<Button sx={props.sx} variant="outlined" component="span" >{props.children}</Button>
		</Link>
	);
};

export default NavigationLink;
