import { useContext, useState } from "react";

import {
	ThumbUpOffAlt,
	ThumbUpAlt,
	ThumbDownOffAlt,
	ThumbDownAlt
} from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import { CardActions, IconButton, Typography } from "@mui/material";
import { PostContext } from "../../context/post-context";
import { AuthContext } from "../../context/auth-context";

const PostButtons: React.FC<{
	score: number;
	commentCount: number;
	userId: string;
	id: string;
	expanded: boolean;
	expandButtonHandler: () => void;
}> = props => {
	const postCtx = useContext(PostContext);
	const authCtx = useContext(AuthContext);

	const [isLiked, setIsLiked] = useState(
		postCtx.votes[authCtx.username] === 1
	);
	const [isDisliked, setIsDisliked] = useState(
		postCtx.votes[authCtx.username] === -1
	);

	const likeButtonHandler = () => {

        if (!authCtx.user) {
            alert("Sign in to vote");
            return;
        }

		if (isDisliked) {
			postCtx.updaters.voteOnPost!(
				props.id,
				"UNDISLIKE",
				authCtx.username
			);
			setIsDisliked(false);
		}

		postCtx.updaters.voteOnPost!(
			props.id,
			!isLiked ? "LIKE" : "UNLIKE",
			authCtx.username
		);
		setIsLiked(state => !state);
	};

	const dislikeButtonHandler = () => {

        if (!authCtx.user) {
            alert("Sign in to vote");
            return;
        }

		if (isLiked) {
			setIsLiked(false);

			postCtx.updaters.voteOnPost!(props.id, "UNLIKE", authCtx.username);
		}
		setIsDisliked(state => !state);
		postCtx.updaters.voteOnPost!(
			props.id,
			!isDisliked ? "DISLIKE" : "UNDISLIKE",
			authCtx.username
		);
	};

	return (
		<CardActions disableSpacing >
			<IconButton onClick={likeButtonHandler}>
				{isLiked ? (
					<ThumbUpAlt sx={{ color: "black" }} />
				) : (
					<ThumbUpOffAlt sx={{ color: "black" }} />
				)}
			</IconButton>
			<Typography variant="h5" sx={{ color: "black" }}>
				{props.score}
			</Typography>
			<IconButton
				onClick={dislikeButtonHandler}
				sx={{ ml: "0px !important" }}
			>
				{isDisliked ? (
					<ThumbDownAlt sx={{ color: "black" }} />
				) : (
					<ThumbDownOffAlt sx={{ color: "black" }} />
				)}
			</IconButton>
			<IconButton onClick={props.expandButtonHandler} sx={{ml: "auto"}} >
				<CommentIcon sx={{ color: "black" }} />
			</IconButton>
		</CardActions>
	);
};

export default PostButtons;
