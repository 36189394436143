import { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut
} from "firebase/auth";
import { db } from "../firebase";
import { get, ref, set, query, orderByChild, equalTo } from "firebase/database";
import { auth } from "../firebase";

export const AuthContext = createContext(
	{} as {
		user: any;
		username: string;
		authFunctions: {
			createAccount: (
				email: string,
				username: string,
				password: string
			) => Promise<{
				status: string;
				message: any;
			}>;
			logIn: (
				email: string,
				password: string
			) => Promise<{
				status: string;
				message: any;
			}>;
			logOut: () => Promise<void>;
			checkLocalStorage: () => void;
		};
	}
);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = props => {
	const [user, setUser] = useState();
	const [username, setUsername] = useState("");

	const navigate = useNavigate();

	const authFunctions = {
		createAccount: async (
			email: string,
			username: string,
			password: string
		) => {
			let creds: any;
			let usernameCheck = false;

			try {
				usernameCheck = (
					await get(ref(db, `user/${username}/`))
				).exists();
				if (usernameCheck) {
					return {
						status: "error",
						message: "auth/username-taken)."
					};
				}

				creds = await createUserWithEmailAndPassword(
					auth,
					email,
					password
				);

				await set(ref(db, `user/${username}/`), {
					email,
					followerCount: 0,
					following: []
				});

				setUsername(username);
				setUser(creds.user);
				localStorage.setItem("user", JSON.stringify(creds.user));
				localStorage.setItem("username", username);
				navigate("/");
				return { status: "success", message: "" };
			} catch (error: any) {
				return { status: "error", message: error.message };
			}
		},
		logIn: async (email: string, password: string) => {
			let creds: any;
			let nameFinder: any;
			let que: any;

			try {
				creds = await signInWithEmailAndPassword(auth, email, password);
				setUser(creds.user);

				que = query(
					ref(db, "user/"),
					orderByChild("email"),
					equalTo(email)
				);
				nameFinder = (await get(que)).val();

				setUsername(Object.keys(nameFinder)[0]);

				localStorage.setItem("user", JSON.stringify(creds.user));
				localStorage.setItem("username", Object.keys(nameFinder)[0]);

				navigate("/");
				return { status: "success", message: "" };
			} catch (error: any) {
				console.log(error);
				return { status: "error", message: error.message };
			}
		},
		logOut: async () => {
			await signOut(auth);
			setUser(undefined);
			localStorage.clear();
		},
		checkLocalStorage: () => {
			if (localStorage.getItem("user")) {
				setUser(JSON.parse(localStorage.getItem("user")!));
				setUsername(localStorage.getItem("username")!);
			}
		}
	};

	return (
		<AuthContext.Provider value={{ user, username, authFunctions }}>
			{props.children}
		</AuthContext.Provider>
	);
};
