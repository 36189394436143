import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDlfbVGhQ9UnVPLPm6az7GgTWIXO-r9nQ8",
	authDomain: "husocial-c2eee.firebaseapp.com",
	databaseURL: "https://husocial-c2eee-default-rtdb.firebaseio.com",
	projectId: "husocial-c2eee",
	storageBucket: "husocial-c2eee.appspot.com",
	messagingSenderId: "456225457575",
	appId: "1:456225457575:web:1b11d1c54783cdbf8fe282"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);

export const auth = getAuth(app);
