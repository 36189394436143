import { useState } from "react";

import { Card } from "@mui/material";
import Post from "../models/post-model";
import PostButtons from "./card contents/PostButtons";
import PostHeader from "./card contents/PostHeader";
import PostText from "./card contents/PostText";
import PostComments from "./card contents/PostComments";

const PostCard: React.FC<{ data: Post }> = props => {
	const [expanded, setExpanded] = useState(false);

    const expandButtonHandler = () => {
        setExpanded(s => !s);
    };

	return (
		<Card sx={{ m: 2, bgcolor: "#F1F0CC" }}>
			<PostHeader authorId={props.data.author} />
			<PostText content={props.data.content} />
			<PostButtons
				score={props.data.score}
				commentCount={props.data.comments.length}
				userId="guest"
				id={props.data.id!}
                expanded={expanded}
                expandButtonHandler={expandButtonHandler}
			/>
            <PostComments in={expanded} comments={props.data.comments} postID={props.data.id!} />
               
		</Card>
	);
};

export default PostCard;
