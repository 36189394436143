import { Card } from "@mui/material";
import Comment from "../models/comment-model";
import PostHeader from "./card contents/PostHeader";
import PostText from "./card contents/PostText";


const CommentCard: React.FC<{data: Comment}> = props => {

    return (
		<Card sx={{ m: 2, bgcolor: "#D1D0AC" }}>
			<PostHeader authorId={props.data.author} />
			<PostText content={props.data.content} />
		</Card>
	);
};

export default CommentCard;