import Comment from "./comment-model";

class Post {
    author: string;
    content: string;
    score: number;
    comments: Comment[];
    timestamp: number;
    id?: string;

    constructor(author: string, content: string, id?: string, score?: number, comments?: Comment[], timestamp?: number) {
        this.author = author;
		this.content = content;
		score ? this.score = score : this.score = 0;
        comments ? this.comments = comments : this.comments = [];
        timestamp ? this.timestamp = timestamp : this.timestamp = new Date().getTime();
        id ? this.id = id: this.id = undefined;
    }


}

export default Post;